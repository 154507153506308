<template>
    <div class="login-page">
        <div class="login-box">
            <div class="title">
                <span>校园生活后台管理系统</span>
            </div>
            <div class="content">
                <el-form :model="loginData" status-icon :rules="rules" ref="loginForm" @submit.native.prevent>
                    <el-form-item prop="username">
                        <el-input v-model="loginData.phone">
                            <i slot="prefix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            v-model="loginData.password"
                            autocomplete="off"
                        >
                            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            native-type="submit"
                            type="primary"
                            @click="submitLogin('loginForm')"
                            style="width: 100%"
                        >提交
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div style="position: absolute; bottom: 30px; display: flex; align-items: center; justify-content: center;">
                <span>备案号：</span>
                <a href="https://beian.miit.gov.cn/"  target="_blank">闽ICP备2022013365号-1</a>
            </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data () {
        // 验证密码是否正确
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                if (this.loginData.checkPass !== '') {
                    this.$refs.loginForm.validateField('checkPass')
                }
                callback()
            }
        }

        return {
            // 验证规则
            rules: {
                phone: [
                    { required: true, message: '请输手机号', trigger: 'change' }
                ],
                password: [{ validator: validatePass, trigger: 'change' }]
            },

            // 登录数据
            loginData: {
                phone: '',
                password: ''
            }
        }
    },
    mounted () {
        console.log('Login')
    },
    methods: {
        submitLogin (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.post('/login', this.loginData).then((res) => {
                        console.log(res)
                        if (res.code === 200) {
                            localStorage.setItem('token', res.data.token)
                            this.$router.push('/home')
                        }
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.login-page {
    height: 100%;
    display: flex;
    align-items: center;
    background: #f0f2f5;
    justify-content: center;
    position: relative;

    .login-box {
        // display: flex;
        text-align: center;
        width: 360px;
        height: 320px;
        background-color: #ffffff;
        padding: 40px 30px 20px;

        .title {
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-size: 28px;
            color: #0d2f54;
            margin-bottom: 20px;
        }

        .content {
            .el-form-item {
                margin-bottom: 30px;
            }
        }
    }
}
</style>
